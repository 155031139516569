import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ArrowZ from "../../common/ArrowZ"
import Img from "../../common/Img"

const Xnum = () => {
  let services = [
    {
      src: "/asset/img/common/csw/csw_05.jpg",
      src2x: "/asset/img/common/csw/csw_05@2x.jpg",
      srcWebp: "/asset/img/common/csw/csw_05.jpg.webp",
      srcWebp2x: "/asset/img/common/csw/csw_05@2x.jpg.webp",
      alt: "CRISP SALAD WORKS",
    },
    {
      src: "/asset/img/common/csw/csw_01.jpg",
      src2x: "/asset/img/common/csw/csw_01@2x.jpg",
      srcWebp: "/asset/img/common/csw/csw_01.jpg.webp",
      srcWebp2x: "/asset/img/common/csw/csw_01@2x.jpg.webp",
      alt: "Azabu-Juban",
    },
    {
      src: "/asset/img/common/csw/csw_02.jpg",
      src2x: "/asset/img/common/csw/csw_02@2x.jpg",
      srcWebp: "/asset/img/common/csw/csw_02.jpg.webp",
      srcWebp2x: "/asset/img/common/csw/csw_02@2x.jpg.webp",
      alt: "Azabu-Juban",
    },
    {
      src: "/asset/img/common/csw/csw_06.jpg",
      src2x: "/asset/img/common/csw/csw_06@2x.jpg",
      srcWebp: "/asset/img/common/csw/csw_06.jpg.webp",
      srcWebp2x: "/asset/img/common/csw/csw_06@2x.jpg.webp",
      alt: "CRISP SALAD WORKS",
    },
  ]

  return (
    <section className="p-service-xnum">
      <div className="p-service-xnum__wrapper">
        <div className="swiper-container p-service-xnum__imgs">
          <ul className="js-scroll__dark swiper-wrapper p-service-xnum__imgs-list">
            {services.map(data => {
              return (
                <li
                  className="swiper-slide p-service-xnum__imgs-item"
                  key={data.src}
                >
                  <div className="p-service-xnum__imgs-item-img">
                    <Img
                      src={data.src}
                      src2x={data.src2x}
                      webpSrc={data.srcWebp}
                      webpSrc2x={data.srcWebp2x}
                      alt={data.alt}
                    />
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="p-contents p-contents__xnum-service">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">x 18</h2>
            <div className="p-contents__block">
              <p className="c-txt__main p-contents__txt">
                CRISP SALAD WORKS
                は麻布十番、六本木ヒルズ、吉祥寺など東京都内に17店舗、及び大阪に1店舗を展開。レストランを運営しながらも、通常の外食産業では考えられないような体験を常に研究し続けています。
              </p>
            </div>
          </div>
          <a
            className="js-hover p-contents__link"
            data-hover-in-time="600"
            data-hover-out-time="650"
            href="https://www.crisp.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="c-link__main p-contents__link-wrapper">
              <span className="p-contents__link-span">
                <span className="c-link__main-inner">CRISP SALAD WORKS</span>
                <ArrowZ />
              </span>
            </div>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Xnum
