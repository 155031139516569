import React from "react"

const Restaurant = () => {
  return (
    <section className="p-service-restaurant">
      <div className="p-service-restaurant__wrapper">
        <div className="p-contents p-contents__restaurant-service">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">RESTAURANT</h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__main p-contents__ttl">
                体験価値を最大化することで、
                <br className="u-none__sp" />
                人々の期待を超え続ける
                <br className="u-none__sp" />
                コネクティッド・レストランへ。
              </h3>
            </div>
          </div>
          <div className="p-contents__content">
            <p className="c-txt__main p-contents__content-txt">
              CRISP SALAD
              WORKSは美味しくて満足できるサラダを、一つひとつ手づくりで提供するカスタムサラダ専門のレストランです。オンラインでもオフラインでも、サラダを通じてお客様とちょっとした心の交流ができるような、より一人ひとりに最適なレストラン体験を提供します。
            </p>
            <span className="u-space__def u-none__pc" />
            <p className="c-txt__main p-contents__content-txt">
              今後はさらに店舗での体験価値を高めていくために、テクノロジー・デザイン・ヘルス・ロボティクス・ロジスティクスの分野に徹底的に集中することで「非連続な成長」と「高い収益率」を実現する、外食の新しいビジネスモデル「コネクティッド・レストラン」を目指していきます。
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Restaurant
