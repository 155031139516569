import React from "react"
import Layout from "../../components/common/Layout"
import Footer from "../../components/common/Footer"
import CareersLink from "../../components/common/CareersLink"
import Learn from "../../components/common/Learn"
import ArrowZ from "../../components/common/ArrowZ"
import Cursor from "../../components/common/Cursor"
import Kv from "../../components/common/Kv"
import { StaticImage } from "gatsby-plugin-image"
import Restaurant from "../../components/section/service/Restaurant"
import Xnum from "../../components/section/service/Xnum"

export default function Service() {
  let title = "Service"
  let description = "私たちのアプローチ、そのすべてを公開しています。"
  let page = "service"
  let headTtl = "SERVICE"
  let blockTtl =
    '私たちは飲食店のDXを通じて<br class="u-none__sp">外食業界にイノベーションを起こす、<br class="u-none__sp">全く新しい外食企業です。'
  // let blockTxt = "創業当初から飲食業を愛するメンバーが集まり、より良いお店をつくるために様々なトライ＆エラーを繰り返し前に進んできたCRISP。レストランの運営だけでなく、飲食に携わる私たちならではの発想で、飲食店の課題解決に特化したAPPやSaaSも自社開発してきました。いま、私たちが培ってきた知見や専門性は、サラダを通じて新しいレストラン体験を提供する「CRISP SALAD WORKS」と、すべての飲食店のDXを後押しするためのデジタルソリューション「CRISP PLATFORM」に展開されています。"
  let blockTxt =
    "創業当初から飲食業を愛するメンバーが集まり、より良いお店をつくるために様々なトライ＆エラーを繰り返し前に進んできたCRISP。レストランの運営だけでなく、飲食に携わる私たちならではの発想で、飲食店の課題解決に特化したAPPやSaaSも自社開発してきました。いま、私たちが培ってきた知見や専門性は、サラダを通じて新しいレストラン体験を提供する「CRISP SALAD WORKS」に展開されています。"
  let dark = "js-scroll__dark"
  let kvSrc = "/asset/img/service/kv.jpg"
  let kvSrc2x = "/asset/img/service/kv@2x.jpg"
  let kvSrcWebp = "/asset/img/service/kv.jpg"
  let kvSrcWebp2x = "/asset/img/service/kv@2x.jpg"
  let kvSrcSp = "/asset/img/service/sp/kv.jpg"
  let kvSrcWebpSp = "/asset/img/service/sp/kv.jpg.webp"
  // let imgSp =
  //   '<source media="(max-width:767px)" srcset="/asset/img/service/sp/kv.jpg">'
  let kvAlt = "crispのサラダ"

  return (
    <Layout title={title} description={description}>
      <div id="page">
        <div id="service" className="js-page">
          <main id="main">
            <div id="contents" className="contents">
              <Cursor />
              <Kv
                page={page}
                headTtl={headTtl}
                blockTtl={blockTtl}
                blockTxt={blockTxt}
                dark={dark}
                kvSrc={kvSrc}
                kvSrc2x={kvSrc2x}
                kvSrcWebp={kvSrcWebp}
                kvSrcWebp2x={kvSrcWebp2x}
                kvSrcSp={kvSrcSp}
                kvSrcWebpSp={kvSrcWebpSp}
                kvAlt={kvAlt}
              />
              <Restaurant />
              <Xnum />
              <Learn backgroundColor={"gradation"} />
              <CareersLink />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}
